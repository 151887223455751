<template>
    <CRow id="packageRenewRow">
        <CCol col="12" id="packageRenewCol">
            <transition name="fade" id="packageRenewTransition">
                <CCard v-if="pageLoading" class="shadow-sm" id="packageRenewCard">
                    <CCardBody v-if="planName" id="packageRenewCardBody">
                        <div class="text-center" id="packageRenewStepContainer">
                            <div class="stepbar row" id="packageRenewStepBar">
                                <div class="col-2 text-left" id="packageRenewStep1">
                                    <CButton :class="{ 'step-button-active': true }" color="success"
                                        id="packageRenewStep1Button">1
                                    </CButton>
                                </div>
                                <div id="packageRenewStep2" style="margin-bottom: 2px;" class="col-4 text-center">
                                    <CButton id="packageRenewStep2Button"
                                        :class="{ 'step-button-active': progressValue >= 33, 'step-button': progressValue < 40 }"
                                        color="success">2
                                    </CButton>
                                </div>
                                <div style="margin-bottom: 2px;" class="col-4 text-center" id="packageRenewStep3">
                                    <CButton id="packageRenewStep3Button"
                                        :class="{ 'step-button-active': progressValue >= 66, 'step-button': progressValue < 90 }"
                                        color="success">3
                                    </CButton>
                                </div>
                                <div style="margin-bottom: 2px;" class="col-2 text-right" id="packageRenewStep4">
                                    <CButton id="packageRenewStep4Button"
                                        :class="{ 'step-button-active': progressValue >= 90, 'step-button': progressValue < 90 }"
                                        color="success">4
                                    </CButton>
                                </div>
                                <div style="margin-bottom: 2px;" class="col-12 mt-2 progress-bar text-center"
                                    id="packageRenewProgressBar">
                                    <CProgress color="success" variant="striped" animated :value="progressValue"
                                        id="packageRenewProgress" />
                                </div>
                            </div>
                        </div>
                        <div v-if="stage == 1" class="mt-5" id="packageRenewStage1">
                            <h3 id="packageRenewStage1Title">{{ $t('renewPackage') }}</h3>
                            <div id="packageRenewStage1Content" class="row">
                                <div class="col-lg-12 col-md-12 col-sm-12" id="packageRenewStage1CardContainer">
                                    <CCard class="current-pack-card" id="packageRenewCurrentPackCard">
                                        <CCardBody id="packageRenewCurrentPackCardBody">
                                            <CCardTitle id="packageRenewCurrentPackCardTitle">
                                                <div class="row" id="packageRenewCurrentPackCardRow">
                                                    <div class="col-8" id="packageRenewCurrentPackCardLeft">
                                                        <div class="d-flex" id="packageRenewCurrentPackCardName">
                                                            {{ $t('packageName') }} {{
                                                                getPlan(planName) }} <div class="row h5 ml-2 "
                                                                id="packageRenewCurrentPackCardBadge">
                                                                <!-- <CBadge class="ml-2 mt-1" color="light">{{
                                                                    shopPackage.type }}</CBadge> -->
                                                                <CBadge id="packageRenewCurrentPackCardBadgeNumber"
                                                                    class="ml-2 mt-1" color="light">#{{
                                                                        shopPackage.packageNo }}</CBadge>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4 h5 text-right"
                                                        id="packageRenewCurrentPackCardRight">{{
                                                            $t('billingPackageCycle')
                                                        }}
                                                        {{
                                                            shopPackage.billingCycle }}
                                                    </div>
                                                </div>

                                            </CCardTitle>
                                            <CCardText style="font-size: medium;" id="packageRenewCurrentPackCardText">
                                                <!-- <div class="row mt-1">
                                                    <div v-if="shopPackage.start" class="col-6 text-left">
                                                        {{ $t('startPackate') }}
                                                    </div>
                                                    <div v-if="shopPackage.start" class="col-6 text-right">
                                                        {{ formatDate(shopPackage.start) }}
                                                    </div>
                                                </div>
                                                <div class="row mt-1">
                                                    <div v-if="shopPackage.end" class="col-6 text-left">
                                                        {{ $t('endPackate') }}
                                                    </div>
                                                    <div v-if="shopPackage.end" class="col-6 text-right">
                                                        {{ formatDate(shopPackage.end) }}
                                                    </div>
                                                </div> -->
                                                <div class="row mt-2" id="packageRenewPeriodRow">
                                                    <div class="col-6 text-left " id="packageRenewPeriodLabel">
                                                        {{ $t('period') }}
                                                    </div>
                                                    <div class="col-6 text-right" id="packageRenewPeriodValue">
                                                        <template v-if="shopPackage.period < 365">
                                                            {{ $t('monthly') }} ( {{
                                                                shopPackage.period }} {{ $t('days') }} )
                                                        </template>
                                                        <template v-else>
                                                            {{ $t('yearly') }} ( {{
                                                                shopPackage.period }} {{ $t('days') }} )
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="row mt-2" id="packageRenewPriceRow">
                                                    <div class="col-6 text-left " id="packageRenewPriceLabel">
                                                        {{ $t('pricePackage') }}
                                                    </div>
                                                    <div class="col-6 text-right h4 text-success"
                                                        id="packageRenewPriceValue">
                                                        {{ getCurrency(shopPackage.price) }} {{ $t('baht') }}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div class="row mt-3" id="packageRenewShopsRow">
                                                    <div v-for="shopDatail in shopPackage.shops"
                                                        class="col-12 text-left pl-4 h5"
                                                        id="packageRenewShopDetailName">
                                                        • {{ shopDatail.name }}
                                                    </div>
                                                </div>
                                            </CCardText>
                                        </CCardBody>
                                    </CCard>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6 p-1" id="packageRenewBackButtonContainer">
                                    <CButton id="packageRenewBackButton" style="width: 98%;"
                                        @click="$router.push('/user')" color="light">{{
                                            $t('back') }}
                                    </CButton>
                                </div>
                                <div id="packageRenewNextButtonContainer" class="col-sm-12 col-md-6 p-1">
                                    <CButton id="packageRenewNextButton" style="width: 98%;" @click="nextStage()"
                                        color="success">{{ $t('next') }}
                                    </CButton>
                                </div>
                            </div>

                        </div>
                        <div v-if="stage == 2" class="mt-5" id="packageRenewStage2">
                            <CAlert v-if="alert" color="danger" id="packageRenewStage2AlertSelectPaymentMethod">
                                {{ $t('alertSelectPaymentMethod') }}
                            </CAlert>
                            <h3 id="packageRenewStage2Title">{{ $t('selectPaymentedthod') }}</h3>
                            <span id="packageRenewStage2Desc">{{ $t('selectPaymentedthodDescription') }}</span>

                            <!-- Select Payment Method Button -->
                            <div class="mt-4" id="packageRenewStage2SelectDiv">
                                <CButton id="packageRenewStage2QRPayment" @click="" style="font-size: large;"
                                    color="light">
                                    {{
                                        $t('QRPayment') }}
                                </CButton>
                            </div>

                            <!-- Payment Method Lists -->
                            <div class="text-center mb-4" id="packageRenewStage2MethodList">
                                <div class="row" id="packageRenewStage2MethodListRow">
                                    <div class="col-md-6 col-sm-12 d-flex justify-content-center"
                                        id="packageRenewStage2MethodListDivBtn">
                                        <CButton id="packageRenewStage2MethodListButton" @click="setPaymentMethod('qr')"
                                            :class="{ 'payment-option': true, 'payment-option-active': paymentMethod == 'qr' }">
                                            <img class="mb-4" src="/img/PromptPay.png" height="30%"><br>
                                            {{ $t('QRPromptPay') }}<br>
                                            ( {{ getCurrency(shopPackage.price) }} THB )
                                        </CButton>


                                    </div>
                                    <!-- <div class="col-md-6 col-sm-12 d-flex justify-content-center">
                                        <CButton @click="setPaymentMethod('creditcard')"
                                            :class="{ 'payment-option': true, 'payment-option-active': paymentMethod == 'creditcard' }">
                                            <img class="mb-4" src="/img/visa-mastercard-unionpay.png" height="30%"><br>
                                            {{ $t('QRCredit') }}<br>
                                            ( {{ getCurrency(shopPackage.price) }} THB )
                                        </CButton>
                                    </div> -->
                                </div>
                            </div>

                            <div class="mt-5 d-flex justify-content-center">
                                <div class="col-6 text-center">
                                    <CButton id="packageRenewStage2MethodListBackBtn" style="width: 98%;"
                                        @click="reverseStage()" color="light">
                                        {{ $t('back') }}
                                    </CButton>
                                </div>
                                <div class="col-6 text-center">
                                    <CButton id="packageRenewStage2MethodListNextBtn" style="width: 98%;"
                                        @click="nextStage()" color="success">
                                        {{ $t('next') }}
                                    </CButton>
                                </div>
                            </div>
                        </div>

                        <div v-if="stage == 3" class="mt-5" d="packageRenewStage3">
                            <h3 id="packageRenewScanForPayTitle">{{ $t('scanForPay') }}</h3>
                            <span id="packageRenewScanForPayDesc">{{ $t('scanForPayDescription') }}</span>
                            <div class="mt-4 row d-flex justify-content-center" id="packageRenewQRCodeContainer">
                                <div class=" col-sm-12 col-md-5 d-flex justify-content-center"
                                    id="packageRenewQRCodeWrapper">
                                    <div class="qr-payment text-center  pb-4" id="packageRenewQRPayment">
                                        <div class="qr-header p-2 text-center mb-3" id="packageRenewQRHeader">
                                            <img width="30%" src="/img/PromptPayHeader.png"
                                                id="packageRenewQRHeaderImg">
                                        </div>
                                        <img v-if="paymentMethod == 'qr'" class="mt-1" width="25%"
                                            id="packageRenewQRImg" src="/img/PromptPay.png">
                                        <img v-else-if="paymentMethod == 'creditcard'" class="mt-1" width="35%"
                                            id="packageRenewCreditCardImg" src="/img/visa-mastercard-unionpay.png">
                                        <img v-if="timeLimit > 0" class="" :src="qrString" width="80%"
                                            id="packageRenewGeneratedQRImg">

                                        <div v-if="timeLimit <= 0" class="mt-4 mb-4" id="packageRenewQRCodeExpired">
                                            <i style="font-size: 90px;" class="fi fi-rr-time-forward"
                                                id="packageRenewTimeIcon"></i><br>
                                            <h4 id="packageRenewQRCodeExpiredMessage"> {{ $t('QRCodeExpired') }}</h4>
                                        </div>

                                        <h5 id="packageRenewCompanyName">บจก. สีลม เทคโนโลยี</h5>
                                        <h5 id="packageRenewPayToAmount">{{ $t('payToAmount') }} {{
                                            getCurrency(shopPackage.price) }} THB
                                        </h5>
                                        <hr style="width: 80%;" />
                                        <div class="mt-4" id="packageRenewCountdownContainer">
                                            <h5 id="packageRenewCountdownMessage">{{ $t('payWithIn') }} {{ countdown }}
                                                {{ $t('minutes') }}</h5>
                                            <CAlert v-if="loading" color="info" id="packageRenewLoadingAlert">{{
                                                $t('haveNotPaidYet') }}
                                            </CAlert>
                                            <CButton @click="checkPaymentStatus()" v-if="timeLimit > 0" class="mt-2"
                                                color="dark" variant="outline" id="packageRenewCheckStatusButton">
                                                <CSpinner v-if="loading" class="mb-1" component="span" size="sm"
                                                    aria-hidden="true" id="packageRenewLoadingSpinner" />
                                                {{ $t('checkStatus') }}
                                            </CButton>
                                            <CButton @click="reverseStage()" v-if="timeLimit <= 0" class="mt-2"
                                                color="danger" variant="outline" id="packageRenewRetryPaymentButton">
                                                {{ $t('retryPayment') }}</CButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-5 d-flex justify-content-center" id="packageRenewNavigation">
                                <div class="col-12 text-center" id="packageRenewBackButtonContainer">
                                    <CButton style="width: 98%;" @click="reverseStage()" color="light"
                                        id="packageRenewBackButton">{{ $t('back')
                                        }}
                                    </CButton>
                                </div>
                            </div>

                        </div>
                        <div v-if="stage == 4" class="mt-5" id="packageRenewStage4">
                            <div class="mt-5 p-2" id="packageRenewReceiptContainer">
                                <div class=" receipt-review text-center" id="packageRenewReceiptReview">
                                    <div>
                                        <div class="col-12" id="packageRenewSuccessIcon"> <img
                                                src="https://cdn-icons-png.flaticon.com/512/4436/4436481.png"
                                                width="100px" alt="Correct free icon" title="Correct free icon"></div>
                                        <br>
                                        <h2 id="packageRenewTransactionSuccess"> {{ $t('transactionSuccess') }}</h2>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12" id="packageRenewPackageDetails">
                                            <CCard class="current-pack-card " id="packageRenewCardCurrentPackCard">
                                                <CCardBody id="packageRenewCardBodyCurrentPackCard">
                                                    <CCardTitle id="packageRenewCardTitleCurrentPackCard">
                                                        <div class="row">
                                                            <div class="col-8" id="packageRenewPackageNameContainer">
                                                                <div class="d-flex text-left"
                                                                    id="packageRenewPackageNameWrapper">
                                                                    {{ $t('packageName') }} {{
                                                                        getPlan(planName) }} <div class="row h5 ml-2 "
                                                                        id="packageRenewPackageBadgeContainer">
                                                                        <!-- <CBadge class="ml-2 mt-1" color="light">{{
                    shopPackage.type }}</CBadge> -->
                                                                        <CBadge class="ml-2 mt-1" color="light"
                                                                            id="packageRenewPackageBadge">
                                                                            #{{
                                                                                shopPackage.packageNo }}</CBadge>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style="white-space: nowrap;"
                                                                class="col-4 h5 text-right"
                                                                id="packageRenewBillingCycle"> {{
                                                                    $t('billingPackageCycle')
                                                                }}
                                                                {{
                                                                    shopPackage.billingCycle }}
                                                            </div>
                                                        </div>

                                                    </CCardTitle>
                                                    <CCardText style="font-size: medium;" id="packageRenewCardText">
                                                        <div class="row mt-2">
                                                            <div class="col-6 text-left " id="packageRenewPeriodLabel">
                                                                {{ $t('period') }}
                                                            </div>
                                                            <div class="col-6 text-right" id="packageRenewPeriodValue">
                                                                <template v-if="shopPackage.period < 365">
                                                                    {{ $t('monthly') }} ( {{
                                                                        shopPackage.period }} {{ $t('days') }} )
                                                                </template>
                                                                <template v-else id="packageRenewEndDateRow">
                                                                    {{ $t('yearly') }} ( {{
                                                                        shopPackage.period }} {{ $t('days') }} )
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <!-- <div v-if="shopPackage.start" class="row mt-1">
                                                            <div class="col-6 text-left">
                                                                {{ $t('startPackate') }}
                                                            </div>
                                                            <div class="col-6 text-right">
                                                                {{ formatDate(shopPackage.start) }}
                                                            </div>
                                                        </div> -->
                                                        <div v-if="shopPackage.end" class="row mt-1">
                                                            <div class="col-6 text-left" id="packageRenewEndDateLabel">
                                                                {{ $t('endPackate') }}
                                                            </div>
                                                            <div class="col-6 text-right" id="packageRenewEndDateValue">

                                                                <template v-if="loading">
                                                                    <CSpinner as="span" size="sm" aria-hidden="true"
                                                                        id="packageRenewLoadingSpinner" />
                                                                    {{ $t('loading') }}
                                                                </template>
                                                                <template v-else>
                                                                    {{ formatDate(expiredDate) }}
                                                                </template>

                                                            </div>
                                                        </div>
                                                        <!-- <div class="row mt-1">
                                                            <div class="col-6 text-left">
                                                                {{ $t('pricePackage') }}
                                                            </div>
                                                            <div class="col-6 text-right h4 text-success">
                                                                {{ shopPackage.price }} {{ $t('baht') }}
                                                            </div>
                                                        </div> -->
                                                        <hr />
                                                        <div class="row mt-3" id="packageRenewShopDetails">
                                                            <div v-for="shopDatail in shopPackage.shops"
                                                                class="col-12 text-left pl-4 h5"
                                                                id="packageRenewShopDetailNameDetail">
                                                                • {{ shopDatail.name }}
                                                            </div>
                                                        </div>
                                                    </CCardText>
                                                </CCardBody>
                                            </CCard>
                                        </div>
                                    </div>

                                </div>
                                <div v-if="taxRequired" id="packageRenewTaxForm">
                                    <h4 class="mt-5" id="packageRenewTaxFormHeader"> {{ $t('inputTaxForm') }}</h4>

                                    <div class="row mt-4" id="packageRenewTaxpayerNameRow">
                                        <h5 class="col-12" id="packageRenewTaxpayerNameLabel">{{ $t('nameTaxpayer') }}
                                            <b class="text-danger">*</b> </h5>
                                        <input type="text" id="packageRenewTaxpayerNameInput"
                                            class="form-control col-12" :disabled="!taxRequired" />
                                    </div>
                                    <div class="row mt-4" id="packageRenewTaxIDRow">
                                        <h5 class="col-12" id="packageRenewTaxIDLabel">{{ $t('taxID') }} <b
                                                class="text-danger">*</b></h5>
                                        <input type="text" id="packageRenewTaxIDInput" class="form-control col-12"
                                            :disabled="!taxRequired" />
                                    </div>
                                    <div class="row mt-4" id="packageRenewBranchNameRow">
                                        <h5 class="col-12" id="packageRenewBranchNameLabel">{{ $t('branchName') }} ({{
                                            $t('notRequired') }})
                                        </h5>
                                        <input type="text" id="packageRenewBranchNameInput" class="form-control col-12"
                                            :disabled="!taxRequired" />
                                    </div>
                                    <div class="row mt-4" id="packageRenewAddressRow">
                                        <h5 class="col-12" id="packageRenewAddressLabel">{{ $t('address') }} <b
                                                class="text-danger">*</b></h5>
                                        <textarea id="packageRenewAddressInput" :rows="3" type="text"
                                            class="form-control col-12" :disabled="!taxRequired"></textarea>
                                    </div>
                                    <div class="row mt-4" id="packageRenewTelRow">
                                        <h5 class="col-12" id="packageRenewTelLabel">{{ $t('tel') }} ({{
                                            $t('notRequired') }})</h5>
                                        <input type="text" id="packageRenewTelInput" class="form-control col-12"
                                            :disabled="!taxRequired" />
                                    </div>
                                    <div class="row mt-4" id="packageRenewEmailRow">
                                        <h5 class="col-12" id="packageRenewEmailLabel">{{ $t('email') }} ({{
                                            $t('notRequired') }})</h5>
                                        <input type="text" class="form-control col-12" :disabled="!taxRequired"
                                            id="renewPackageEmailInput" />
                                    </div>
                                </div>


                            </div>
                            <div v-if="!taxRequired" class="mt-5 d-flex justify-content-center"
                                id="packageRenewActionButtons">
                                <!-- <div class="col-6 text-center">
                                <CButton style="width: 98%;" @click="taxRequired = !taxRequired" color="light">{{
                                    $t('taxAddress') }}
                                </CButton>
                            </div> -->
                                <div style="width: 100%;" class="row text-center" id="packageRenewActionRow">
                                    <div class="col-md-6 col-sm-12" id="packageRenewPrintButtonContainer">
                                        <CButton :disabled="clicked" style="width: 98%;" @click="getReceipt()"
                                            color="info" id="packageRenewPrintButton">
                                            <CSpinner v-if="clicked" size="sm" id="packageRenewPrintSpinner" />
                                            {{
                                                $t('printReceipt') }}
                                        </CButton>
                                    </div>
                                    <div class="col-md-6 col-sm-12" id="packageRenewExitButtonContainer">
                                        <CButton id="packageRenewExitButton" style="width: 98%;"
                                            @click="$router.push('/user')" color="success">
                                            {{
                                                $t('exit') }}
                                        </CButton>
                                    </div>
                                </div>
                            </div>
                            <div v-else id="packageRenewTaxExitButtonContainer">
                                <CButton class="mt-4" style="width: 100%;" @click="$router.push('/user')"
                                    color="success" id="packageRenewTaxExitButton">{{
                                        $t('exit') }}
                                </CButton>

                            </div>
                        </div>
                    </CCardBody>
                    <div v-else class="text-center mt-5 mb-5" id="packageRenewNoPackageContainer">
                        <img src="   https://cdn-icons-png.flaticon.com/512/7486/7486754.png " width="20%"
                            class="img-small" id="packageRenewNoPackageImg">
                        <br>
                        <h3 class="mt-4" id="packageRenewNoPackageText">{{ $t('noPackage') }}</h3><br>
                        <br>
                    </div>
                </CCard>
            </transition>
        </CCol>
        <div style="position: fixed; width: 100%;bottom:1000vh" id="packageRenewInvoiceContainer">
            <Invoice class="mb-4" v-if="selectedPackageHistory.length !== 0"
                v-bind:packageHistory="selectedPackageHistory" id="packageRenewInvoice">
            </Invoice>
        </div>
    </CRow>
</template>


<script>
import { mapGetters } from 'vuex'
import DataTable from '@/containers/DataTable'
import pos from '@/services/local'
import util from '@/util/util'
import axios from 'axios'
import Invoice from '../template/Receipt-A4.vue'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import asymmetricToken from '@/util/token'
import moment from 'moment'
import jwt from 'jsonwebtoken'



export default {
    components: {
        DataTable,
        Invoice,
    },
    data() {
        return {
            shopPackage: [],
            progressValue: 0,
            stage: 1,
            taxRequired: false,
            paymentMethod: 'qr',
            visible: false,
            QRPayment: true,
            timeLimit: 10 * 60,
            alert: false,
            countdownTimer: null,
            checkStatus: null,
            qrString: '',
            planName: '',
            packageHistoryLength: 0,
            loading: false,
            pageLoading: false,
            clicked: false,
            selectedPackageHistory: [],
            packageHistoryReceipt: [],
            shop: [],
            expiredDate: '',
            checkExpiredDate: null,
            orderIdFromQR: '',
        }

    },
    computed: {
        ...mapGetters(['shops']),
        uid() {
            return `${localStorage.getItem('uid')}`
        },
        shopObjectId() {
            return `${localStorage.getItem('shopObjectId')}`
        },
        countdown() {
            let minutes = Math.floor(this.timeLimit / 60)
            let seconds = this.timeLimit % 60
            minutes = minutes < 10 ? '0' + minutes : minutes
            seconds = seconds < 10 ? '0' + seconds : seconds

            return `${minutes}:${seconds}`
        },

    },

    created() {
        this.getPackage()
        this.getPackageDataBeforeRenew()

    },
    beforeDestroy() {
        clearInterval(this.countdownTimer)
        clearInterval(this.checkStatus)
        clearInterval(this.checkExpiredDate)
    },
    mounted() {
        this.checkIfInAppBrowser();
    },
    methods: {
        ...util,
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                // Get the browser name
                let browserName = "Unknown";
                const userAgent = navigator.userAgent.toLowerCase();

                if (userAgent.includes("line")) browserName = "LINE Browser";
                else if (userAgent.includes("fbav") || userAgent.includes("fb_iab")) browserName = "Facebook Messenger";
                else if (userAgent.includes("chrome") && !userAgent.includes("edg")) browserName = "Chrome";
                else if (userAgent.includes("firefox")) browserName = "Firefox";
                else if (userAgent.includes("safari") && !userAgent.includes("chrome")) browserName = "Safari";
                else if (userAgent.includes("edg")) browserName = "Edge";
                else if (userAgent.includes("opr") || userAgent.includes("opera")) browserName = "Opera";

                // Send event to Google Analytics
                window.gtag('event', 'click', {
                    event_category: 'User Interaction',
                    event_label: buttonLabel,
                    browser: `${buttonLabel}/${browserName}`,
                });

                console.log(`Event tracked: ${buttonLabel}, Browser: ${browserName}`);
            } else {
                console.error('gtag is not defined.');
            }
        },
        getCurrency(amount) {
            return util.convertCurrency(amount)
        },
        async getPackageDataBeforeRenew() {

            const uid = `${localStorage.getItem('uid')}`
            let params = {
                shopObjectId: this.shopObjectId,
                extraFilter : 'price',
                extraVal : 100,
                extraOperator : 'greater'
            }
            // Get Expired Date from MongoDB 
            await pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/UserPackage',
                params: params,
            }).then((res) => {
                let selectedPackage = localStorage.getItem("packageObjectId")

                this.shopPackage = res.data.data.find(
                    (item) => item.objectId === selectedPackage
                )
            })

            // Get Expired Date from Firebase 
            await pos
                .get('/api/v1.0/' + uid + '/Shop/data')
                .then((res) => {
                    const shop = res.data.data.documents.find(
                        (shop) => shop.objectId === this.shopPackage.shops[0].objectId
                    )
                    this.expiredDate = shop.expire
                })
        },

        async getReceipt() {
            let params = {
                shopObjectId: this.shopObjectId
            }
            let uid = this.uid
            await pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/UserPackageHistory',
                params: params,
            }).then((res) => {
                this.packageHistoryReceipt = res.data.data
            })


            this.clicked = true
            this.selectedPackageHistory = this.packageHistoryReceipt[0]
            setTimeout(() => {
                const invoice = this.$el.querySelector('#invoice')
                html2canvas(invoice).then((canvas) => {
                    const imgData = canvas.toDataURL('image/jpeg')
                    const pdf = new jsPDF('p', 'pt', 'a4')
                    const imgWidth = 595.28 // A4 width in pt
                    const pageHeight = 841.89 // A4 height in pt
                    const imgHeight = (canvas.height * imgWidth) / canvas.width
                    let heightLeft = imgHeight
                    let position = 0

                    pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                    heightLeft -= pageHeight

                    while (heightLeft > 0) {
                        position = heightLeft - imgHeight
                        pdf.addPage()
                        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight)
                        heightLeft -= pageHeight
                    }
                    // Generate the PDF as a Blob and open it in a new tab
                    const pdfBlob = pdf.output('blob')
                    const pdfUrl = URL.createObjectURL(pdfBlob)
                    window.open(pdfUrl)
                    this.clicked = false
                });
            }, 2000);
        },
        checkPaymentStatus() {
            this.loading = true;
            this.getPackageHistory()
            setTimeout(() => {
                this.loading = false
            }, 3000)
        },
        getShop() {
            const uid = `${localStorage.getItem('uid')}`;
            this.loading = true
            pos
                .get('/api/v1.0/' + uid + '/Shop/data')
                .then((res) => {


                    const shop = res.data.data.documents.find(
                        (shop) => shop.objectId === this.shopPackage.shops[0].objectId
                    )

                    if (shop.expire == this.expiredDate) {
                        this.loading = true
                    } else {
                        this.expiredDate = shop.expire
                        this.loading = false
                        clearInterval(this.checkExpiredDate)
                    }
                })

        },
        getPackageHistory() {
            let params = {
                shopObjectId: this.shopObjectId
            }
            let uid = this.uid
            pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/UserPackageHistory',
                params: params,
            }).then((res) => {
                const packageHistory = res.data.data?.find(
                    (item) => item?.paidData?.orderId === this.orderIdFromQR
                );


                if (packageHistory !== undefined) {
                    this.loading = true
                    this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
                    this.trackButtonClick('renew_package_success')
                    this.getPackage()
                    this.nextStage()
                    clearInterval(this.checkStatus)
                    this.checkExpiredDate = setInterval(this.getShop, 2000)
                    setTimeout(() => {
                        this.loading = false
                    }, 5000)
                } else {
                    console.log("Have not Paid")
                }
            })

        },
        async createQRCode(method_) {


            try {
                const url = process.env.VUE_APP_PAYMENT_API_URL + '/gateway/payment/v1.1/2GEPCzEAnZhBh2yXPYUdWmGLu7G3/createqr'
                const headers = {
                    'gateway': 'KBank',
                    'Content-Type': 'application/json',
                    'token': asymmetricToken.sign(),

                };
                const data = {
                    notation: 'USERPACKAGE',
                    ref1: this.shopPackage.objectId,
                    type: method_,
                    amount: this.shopPackage.price.toFixed(2),
                    shop: {
                        objectId: this.shopPackage.shops[0].objectId,
                        id: this.shopPackage.shops[0].id,
                        branchName: this.shopPackage.shops[0].name
                    }
                };
                const response = await axios.post(url, data, { headers })
                this.orderIdFromQR = response.data.data.orderId

                var QRCode = require('qrcode')
                let qrString = ''
                QRCode.toDataURL(response.data.data.qrCode, function (err, url) {
                    qrString = url
                })
                this.qrString = qrString

            } catch (error) {
                console.error('Error creating QR Code:', error)
            }
        },
        setPaymentMethod(method_) {
            this.alert = false
            this.paymentMethod = method_
            clearInterval(this.countdownTimer)
            this.countdownTimer = null
            this.timeLimit = 10 * 60
        },
        nextStage() {
            if (this.progressValue < 100) {
                if (this.stage == 2 && this.paymentMethod !== '') {
                    this.createQRCode(this.paymentMethod)
                    this.checkStatus = setInterval(this.getPackageHistory, 3000) // Repeat every 3 seconds
                    this.progressValue += 34
                    ++this.stage
                } else if (this.stage !== 2) {
                    this.progressValue += 34
                    ++this.stage
                } else {
                    this.alert = true
                }
            }
            if (this.stage == 3) {
                this.setPaymentMethod('qr')
                this.countdownTimer = setInterval(() => {
                    if (this.timeLimit > 0) {
                        this.timeLimit--;
                    } else {
                        clearInterval(this.countdownTimer)
                        clearInterval(this.checkStatus)
                    }
                }, 1000);
            }
        },
        reverseStage() {
            if (this.progressValue > 0) {
                this.progressValue -= 34
                --this.stage
                this.qrString = ''
                this.paymentMethod = 'qr'
                clearInterval(this.checkStatus)
            }

        },
        getPlan(plan) {
            switch (plan) {
                case 'multiplePOS':
                    return 'SMART+';
                case 'starter':
                    return 'STARTER';
                case 'starterPlus':
                    return 'STARTER PLUS';
                default:
                    return plan
            }
        }
        ,
        getPackage() {
            let params = {
                shopObjectId: this.shopObjectId,
                extraFilter : 'price',
                extraVal : 100,
                extraOperator : 'greater'
            }
            let uid = this.uid

            pos({
                method: 'get',
                url: '/api/v2.2/' + uid + '/UserPackage',
                params: params,
            }).then((res) => {

                this.pageLoading = true
                let selectedPackage = localStorage.getItem("packageObjectId")

                this.shopPackage = res.data.data.find(
                    (item) => item.objectId === selectedPackage
                )
                this.planName = this.shopPackage.plan
            })
        },
        formatDate(isoDate) {
            const date = new Date(isoDate);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const formattedDay = day < 10 ? '0' + day : day;
            const formattedMonth = month < 10 ? '0' + month : month;
            return formattedDay + '/' + formattedMonth + '/' + year;
        },
        checkIfInAppBrowser() {
            // const userAgent = navigator.userAgent.toLowerCase();
            // if (userAgent.includes("line") || userAgent.includes("fbav") || userAgent.includes("fb_iab")) {
            //     alert(this.$i18n.t('alertInAppBrowser'));
            // }
        },
    }
}
</script>


<style scoped>
.stepbar {
    width: 97%;
}

.step-button {
    font-size: large;
    width: 40px;
    height: 40px;
    z-index: 1020;
    position: absolute;
    background-color: rgb(220, 220, 220);
    border: none;

}

.progress-bar {
    margin-left: 11px;
    width: 100%;
    z-index: 1000;
    border: none;

}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.step-button-active {
    font-size: large;
    width: 40px;
    height: 40px;
    z-index: 1020;
    position: absolute;
    border-radius: 50%;
    color: #fff;
    background-color: #3abc98;
    border-color: #3abc98;
}

.current-pack-card {
    border: 1px solid #ebebeb;
    border-radius: 5px;
}

.payment-option {

    width: 100%;
    aspect-ratio: 2/1;
    border: 1px solid #ebebeb;
    border-radius: 5px;
    font-size: large;
    margin-top: 2cap;
}

.payment-option-active {
    border: 5px solid #88c1f0;
    width: 100%;
    aspect-ratio: 2/1;
    font-size: large;
    margin-top: 2cap;
}

.qr-payment {
    border: 1px solid #ebebeb;
    width: 100%;
    background-color: white;
    border-radius: 7px;
}

.qr-header {
    width: 100%;
    background-color: #133d65;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
</style>
